.bg-grey {
    background-color: lightgray;
    /* on hover, make background slightly less opaque */
    &:hover {
        background-color: rgba(211, 211, 211, 0.7);
    }
}

.bg-greencheck {
    background-color: #7ac142;
    /* on hover, make background slightly less opaque */
    &:hover {
        background-color: rgba(122, 193, 66, 0.7);
    }
}

.bg-salmon {
    background-color: #56b4f8;
    /* background-color: rgba(250, 128, 114, 1);
    &:hover {
        background-color: rgba(250, 128, 114, 0.7);
    } */
}

.certificate-icon {
    width: 24px;
    height: 24px;
    color: #ac3ff5;
    /* on hover, make background slightly less opaque */
    &:hover {
        color: rgba(172, 63, 245, 0.7);
    }
}

.custom-light-button {
    border: 2px solid #ced4da;
    font-weight: 600;
}



.module-card {
    scroll-snap-align: start;
    /* scroll-margin: 10rem; */
}

.treasure-map-container {
    display: flex ;
    flex-direction: row ;
    border-radius: .2rem ;
    position: sticky ;
    top: 0 ;
    z-index: 10;
    padding:1rem;
  }

  .treasure-map-desktop {
    display: flex ;
    flex-direction: row ;
    border-radius: .2rem ;
    position: sticky ;
    top: 0 ;
    z-index: 2;
    padding:1rem;
    justify-content: center ;
  }

  .treasure-map-mobile {
    display: flex ;
    flex-direction: row ;
    border-radius: .2rem ;
    position: sticky ;
    top: 0 ;
    z-index: 0;
    padding:1rem;
    justify-content: space-between;
  }
  