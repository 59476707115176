.like-button-container {
  display: flex;
  align-items: center;
}

.likeButton {
  cursor: pointer;
  margin-right:5px;
}

.likeButton:hover {
  animation: pulse 0.4s;
}

.num-likes {
  color: gray;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
