/**
 * Modfied version of [termynal.js](https://github.com/ines/termynal/blob/master/termynal.css).
 *
 * @author Ines Montani <ines@ines.io>
 * @version 0.0.1
 * @license MIT
 */
 .react-terminal-wrapper {
    width: 100%;
    height: 100%;
    background: #252a33;
    color: #eee;
    font-size: 14px;
    font-family: Monaco, Menlo, "Ubuntu Mono", Courier, monospace;
    border-radius: 4px;
    padding-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  p[data-type="terminal"] {
    background-color: black;
    color: white;
    font-family: "Nunito", monospace;
    padding: 10px;
    border-radius: 5px;
    font-size:13px;
    font-family: monospace;
    overflow-x:auto;
    text-wrap: nowrap;
  }
  
  
  .react-terminal {
    height: 600px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  
  .react-terminal-wrapper.react-terminal-light {
    background: white;
    color: #1a1e24;
  }
  
  .react-terminal-wrapper:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  
  .react-terminal-wrapper:after {
    content: attr(data-terminal-name);
    position: absolute;
    color: #a2a2a2;
    top: 5px;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  .react-terminal-wrapper.react-terminal-light:after {
    color: black;
  }
  
  .react-terminal-line {
    display: block;
    line-height: 1.5;
  }
  
  .react-terminal-line:before {
    /* Set up defaults and ensure empty lines are displayed. */
    content: "";
    display: inline-block;
    vertical-align: middle;
    color: white;
  }
  
  .react-terminal-light .react-terminal-line:before {
    color: #d76d77;
  }
  
  .react-terminal-input:before {
    margin-right: 0.75em;
    content: "$";
  }
  
  .react-terminal-input[data-terminal-prompt]:before {
    content: attr(data-terminal-prompt);
  }
  
  .react-terminal-wrapper:focus-within .react-terminal-active-input:after {
    content: "▋";
    font-family: monospace;
    margin-left: 0.2em;
    -webkit-animation: blink 1s infinite;
    animation: blink 1s infinite;
  }
  
  /* Cursor animation */
  
  @-webkit-keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
  .terminal-hidden-input {
    position: fixed;
    left: -1000px;
  }
  
  /* .react-terminal-progress {
      display: flex;
      margin: .5rem 0;
    }
    .react-terminal-progress-bar {
      background-color: #fff;
      border-radius: .25rem;
      width: 25%;
    }
    .react-terminal-wrapper.react-terminal-light .react-terminal-progress-bar {
      background-color: #000;
    } */