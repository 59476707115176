#monaco-editor {
    width:100%;
    height:600px;
    border:1px solid #ccc;
}
.yRemoteSelection-red {
    background-color: rgba(250, 0, 0, 0.5)
}

.yRemoteSelection-blue {
    background-color: rgba(0, 0, 250, 0.5)
}

.yRemoteSelection-green {
    background-color: rgba(0, 250, 0, 0.5)
}


.yRemoteSelection-orange {
    background-color: rgba(250, 129, 0, 0.5)
}


.yRemoteSelection-yellow {
    background-color: rgba(250, 250, 0, 0.5)
}

.yRemoteSelection-purple {
    background-color: rgba(250, 0, 250, 0.5)
}





.yRSH-red {
    border-left: red solid 2px;
    border-top: red solid 2px;
    border-bottom: red solid 2px;
}

.yRSH-blue {
    border-left: blue solid 2px;
    border-top: blue solid 2px;
    border-bottom: blue solid 2px;
}

.yRSH-green {
    border-left: green solid 2px;
    border-top: green solid 2px;
    border-bottom: green solid 2px;
}

.yRSH-orange {
    border-left: orange solid 2px;
    border-top: orange solid 2px;
    border-bottom: orange solid 2px;
}

.yRSH-yellow {
    border-left: yellow solid 2px;
    border-top: yellow solid 2px;
    border-bottom: yellow solid 2px;
}

.yRSH-purple {
    border-left: purple solid 2px;
    border-top: purple solid 2px;
    border-bottom: purple solid 2px;
}



.yRemoteSelectionHead {
    position: absolute;
    height: 100%;
    box-sizing: border-box;
}
.yRemoteSelectionHead::after {
    position: absolute;
    content: ' ';
    border: 3px solid green;
    border-radius: 4px;
    left: -4px;
    top: -5px;
}