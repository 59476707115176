.MuiPopper-root {
  z-index: 9999999 !important;
}

.zoomOuter {
  z-index: 999999;

}

#meetingSDKElement {
  z-index: 999999;
  position: absolute !important;
  // right:0;
  pointer-events: none;
  right: 20px;
  bottom: 100px !important;
  margin-left: auto;
  margin-right: auto;
  width: 244px; /* width of speaker and ribbon view */
}

#meetingSDKElement .react-draggable {
  pointer-events: auto;
}

button[class*="MuiButtonBase-root"][title="Leave"]{
  display: none;
}



button[aria-label="See apps that are accessing your meeting content"] {
  display: none;

}
