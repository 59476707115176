.projectSubmissionFormContainer {
  width: 70%;
  margin: auto;
  margin-top: 15px;
}

.titleAndImageInputContainer {
  display: flex;
  width: 100%;
}

.imageUploadContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleInputContainer {
  width: 50%;
}
