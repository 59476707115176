.postSplash {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.splash-icon {
  /* color: rgb(255, 187, 0); */
  -webkit-animation: fa-spin 10s infinite linear;
  animation: fa-spin 10s infinite linear;
}
