

.su-lockup__wordmark {
    display: inline-block;
      font-family: Stanford, 'Source Serif Pro', Georgia, Times, 'Times New Roman', serif;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      text-decoration: none;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      letter-spacing: 0;
      -webkit-font-feature-settings: "liga";
      -ms-font-feature-settings: "liga" 1;
      font-feature-settings: "liga";
      -webkit-font-variant-ligatures: discretionary-ligatures;
      font-variant-ligatures: discretionary-ligatures;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: white;
      font-size: 24px;
      line-height: 24px;
      vertical-align: bottom;
      padding-right:10px;
      margin-right:10px;
      padding:5px;
      background-color: white;
      color:#8C1515;
      border-radius: 5px;
      padding-bottom:4px;
      padding-top:4px;
      
  }




  .su-lockup__wordmarkh1 {
    display: inline-block;
      font-family: Stanford, 'Source Serif Pro', Georgia, Times, 'Times New Roman', serif;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      text-decoration: none;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      letter-spacing: 0;
      -webkit-font-feature-settings: "liga";
      -ms-font-feature-settings: "liga" 1;
      font-feature-settings: "liga";
      -webkit-font-variant-ligatures: discretionary-ligatures;
      font-variant-ligatures: discretionary-ligatures;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: white;
      font-size: 36px;
      line-height: 40px;
      vertical-align: bottom;
      padding-right:10px;
      margin-right:10px;
      padding:5px;
      background-color: white;
      color:#8C1515;
      border-radius: 5px;
      padding-bottom:4px;
      padding-top:4px;
      
  }