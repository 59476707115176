.filterContainer {
  margin-right: 15px;
}

.filterTitle {
  color: #222;
  font-weight: 600;
}

.filterList {
  display: flex;
  flex-direction: column;
}

.filterTag {
  margin-top: 5px;
  cursor: pointer;
}
