
  
  .karel-cross {
    width: 3px;
    height: 3px;
    background-color: black;
    color:black;
    position: absolute;
  }
  
  .karel-stone {
    position: absolute;
  }
  
  .karel-stoneDiamond {
    width: 100%;
    height: 100%;
    border: solid 1px;
    background-color: #1cb7fe;
    transform: rotateY(0deg) rotate(45deg); /* needs Y at 0 deg to behave properly*/
    /*transition: transform 2s;*/
  }
  
  .karel-stoneText {
    position: absolute;
    text-align: center;
  }
  
  .karel {
    position: absolute;
  }
  
.karel-editable-wall{
    position: absolute;
    background-color: lightgray;
    cursor: pointer;
}

.karel-editable-wall:hover{
    background-color: blue;
}

.karel-icon-button {
    padding-right:3px
}

.karel-icon{
    width:16px
}

.karel-editable-canvas{
    border: solid 2px black;
}

.karel-editable-wall-selected{
    position: absolute;
    background-color: black;
    cursor: pointer;
}

.karel-editable-wall-selected:hover{
    background-color: blue;
}

  
  .karel-descriptionContainer {
    overflow-y: scroll;
    border: 1px solid gray;
    margin-top: 20px;
    padding: 10px;
    height: 200px;
  }
  
  .karel-showDescriptions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  