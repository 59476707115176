$sidebar-bg-color: white; //002154
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(white, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.2);
$icon-size: 35px;

.course-page {
    height: calc(100%  - 0px) ;
    display: flex;
    position: relative;

    .content {
      overflow-y:auto;
      flex-grow:4;
      height:100vh;
    }

}

.course-profile-dropdown .dropdown-menu {
  right: 0;
  left: auto;
  z-index: 100;
}

.course-karma-dropdown .dropdown-menu {
  min-width: 300px;
  right: 0;
  left: auto;
  z-index: 100;

}


