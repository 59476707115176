.postEntries {
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding-left:10px; */
  padding-right: 0px;

  scrollbar-width: none;
}

.postActions {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}


.filterResolved {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
