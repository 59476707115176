.postContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.post {
  width: 100%;
  margin-top: 25px;
  max-width: 650px;
}

.postActions {
  margin-left: 3px;
  display: flex;
}

.repliesHeading {
  font-size: 16px;
  font-weight: 700;
  color: #7b7b7b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  margin-top: 40px;
}

.postContent {
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.editButton {
  cursor: pointer;
  margin-right: 10px;
  color: #bbbbbb;
}

.postTitle {
  margin-right: 20px;
}

.editButton:hover {
  color: lightblue;
  animation: pulse 0.4s;
}

.editorContainer {
  margin-top: 15px;
}

.profilePicture {
  margin-right: 8px;
}

.editPostActions {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editPostActions > button {
  margin-left: 10px;
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-header {
  display: flex;
  /* align-items: start;
  justify-content: space-between; */
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.post-header > h2 {
  margin-top: 0px;
}

.date {
  margin-left: 10px;
  color: lightgrey;
}

.userAndTime {
  display: flex;
  align-items: center;
}
