


.line-highlight-step {
    background-color: #90cbff;
}

.line-highlight-error {
    background-color: rgb(245, 130, 130);
}


.feedback-block {
    background-color: #FFF2CD;
    border: 1px solid #FFD700;
    border-radius: 4px;
    padding: 10px;
    width: 90%;
}

#selectionButton {
    position: absolute;
    display: none;
    z-index: 1000;
    background-color: #FFF2CD;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

#selectionButton:hover {
    background-color: #f0e5b2;
}