.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.showcaseContainer {
  max-height: 80vh;
  width: 60vw;
  margin: auto;
  display: flex;
  flex-direction: row;
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px;
  background: white;
}

.showcaseTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left {
  width: 50%;
}

.intro {
  height: 15%;
}

.list {
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 1rem;
}

.right {
  width: 50%;
}

.projectListEntry {
  border: 1px solid lightgrey;
  padding: 5px;
  margin-bottom: 1rem;
  border-radius: 5px;
  /* height: 60px; */
  display: flex;
  flex-direction: column;
}

.projectListEntry:hover {
  background-color: lightblue;
  cursor: pointer;
  border-color: black;
}

.selected {
  background-color: lightblue;
  border-color: black;
}

.showcaseSplash {
  padding: 1rem;
}

.showcaseDetails {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  max-height: 100%;
}

.projectImage {
  width: 80%;
  margin: auto;
}

.linkIcon {
  margin-right: 6px;
}

.showcaseSplash {
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splashTitle {
  width: 100%;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.projectAction {
  cursor: pointer;
}
