.update-warning code {
  color: #c7254e;
  font-family: 'Courier New', Courier, monospace;
  background-color: #f9f2f4;
  border: 1px solid #f7c6c7;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 90%;
}

.map-item:hover {
  filter: brightness(90%);
}
