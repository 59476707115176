.comment {
  width: 100%;
}

.post-metadata {
  padding-left: 0px;
  font-size: small;
  display: flex;
  gap: 5px;
  align-items: center;
  padding-top:10px;
}

.reply-actions {
  margin-top: 4px;
  /* padding-left: 10px; */
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.cancel-reply-button {
  margin-right: 20px;
}

.reply-date {
  color: lightgrey;
}

.action-button {
  cursor: pointer;
  margin-right: 6px;
  color: #bbbbbb;
}



.action-button:hover {
  animation: pulse 0.4s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.saveReplyContainer {
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  padding-right: 10px;
  margin-top: 5px;
}

.tipTapContainer {
  margin-top: 6px;
}
