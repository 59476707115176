
html {
  scroll-behavior: smooth;
}

@media (min-width: 850px) {
  .container {
    max-width: 800px;
  }
}

.quoteContainer {
  height: 100px;
  overflow-y: scroll;
  margin-bottom: 00px;
}
.quote {
  border-left: 5px solid grey;
  padding-left: 10px;
  padding-right: 15px;
}

.global-footer {
    background: #8c1515;
    color: #e3dfd5;
    width:100%;
    height:150px;
    overflow-x: none;
    display: flex;
    justify-content: center;
}

@media (max-width: 850px) {
    .global-footer {
        height:300px;
    }
}

// outer
.landingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.footerContainer {
    border-top:1px solid lightgray;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    width:100%;
    display: flex;
    justify-content: center;
}

.footerOuter {
	padding-top: calc(100vh - 530px);
}

.footerContent {
    height:50px;
    display: flex;
    align-items: center;
}

.footerLink {
    color:black;
}

.footerLink:hover {
    color:black;
}

.socialBits {
    height:30px;
    font-size: 20px;
}

.footer-bottom-logo {
font-weight: 400;
letter-spacing: 0.01em;
font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
font-size: 15px;
line-height: 1em;
color: #e3dfd5;

padding: 0;
margin: 0;
display: block;
min-height: 1px;
text-align: center;
float: left;
}

.footer-bottom-text {
    font-weight: 400;
letter-spacing: 0.01em;
font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
font-size: 15px;
line-height: 1em;
color: #e3dfd5;
min-height: 1px;
float: left;
}

.footer-bottom-list {
font-weight: 400;
letter-spacing: 0.01em;
font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
font-size: 16px;
color: #e3dfd5;
list-style: none;
}

.footer-bottom-list-small {
    font-size: 14px !important;
    margin-top: 8px;
}

.footer-bottom-list a {
    color: #e3dfd5;
    margin-right:20px;
}

.footer-container {
    padding-top:40px;
}

/* 
Bordered:
Style for a pre tag with text that should look like a
console (aka terminal) */
.bordered {
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  padding: 10px;
}

.container-figure {
  max-width:100%
}
@media (min-width: 1200px) {
  .container-figure{
      max-width: 1200px;
  }
}

.stanfordRedLink {
  color: #8c1515;
}

.stanfordRedLink:hover {
  color: #8c1515;
}

.landingNavLink {
  color: black;
  font-size: 18px;
}

.landingNavLink:hover {
  color: #8c1515 !important;
  font-size: 18px;
}

.landingLink:disabled {
  color: rgba(255, 255, 255, 0.3);
}

.site-title-uppercase {
  font-size: 28px;
  margin-bottom: -8px;
  font-weight: 600;
}

.site-title-underneath {
  font-size: 27px;
  font-weight: 400;
  letter-spacing: 0;
  left: 0px;
  bottom: 0px;
  line-height: 1.3em;
}

.aboutIcon {
  width: 30px !important;
}

.landing-action-text {
  height: 90px;
}

.pageOuter {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-centered {
  text-align: center;
}

.website-title {
  font-family: "Source Serif Pro", serif;
  text-align: center;
  font-size: 4rem;
}

.website-title-mobile {
  font-family: "Source Serif Pro", serif;
  text-align: center;
  font-size: 3rem;
  color: white;
}

.website-subtitle-mobile {
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.splashBackground {
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  width:100%;
  // background-image: url("/splash4.jpg");
  // background-image: url("https://trees.stanford.edu/images/Jacaranda/JACmim-memday.jpg");
  // background-image: url("https://www.stanford.edu/wp-content/uploads/2020/06/fountain-hoover-splash-landscape-4000-2048x1365.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landingContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }

  .homeNav-logo {
    height:40px;
    margin-right: 10px;
    margin-left:8px;
    margin-top:-3px;
  }

.cipMap {
  width: 100%;
}

.scrollArrow {
  overflow: hidden;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 0px;
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
  width: 50px;
  height: 50px;
  z-index: 2;
}




// p {
//   text-align: justify;
//   /*font-size:14px;*/
// }

// h3 {
//   margin-top: 30px;
// }

// pre {
//   background-color: white;
// }

// li {
//   text-align: justify;
//   margin-bottom: 5px;
// }

// code {
//   color: black;
//   padding: 0px;
//   border: 0px;
//   background-color: transparent;
//   font-weight: bold;
// }

/**
Handout Styles:
This is the CSS class for a handout. A few notes:
The actual width of content is 700px. When the 
handout is wider than 900px this inclues up to 100px
padding on either side. To make the handout visible
it must be on a grey background (often the body has
greyBackground as a style class)
**/
.handout {
  background-color: white;
  padding: 80px;
  padding-top: 50px;
  max-width: 930px;
  margin-top: 20px;
  margin-bottom: 00px;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
}
.handoutRow {
  display: flex;
  justify-content: center;
}

.text-centered {
  text-align: center;
}

.stanford-teacher-logo {
  max-width: 130px;
  margin-top: 4px;
}

.logo-mobile {
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  display: table-cell;
  padding: 0 10px 0 0;
  vertical-align: bottom;
  margin: 0;
  float: none;
  border-right: 1px solid #2e2d29;
}

.engLogo {
  width: 300px;
}
/* Style for the body of a handout page */
.greyBackground {
  /*background-color: #535659;*/
  width: 100%;
  height: max(100vh, 100%);
}
/* When the handout drops bellow a threshold remove padding */
@media (max-width: 1000px) {
  .greyBackground {
    background-color: white;
  }

  .handout {
    /* Note: since padding drops from 100 to 15, max-width
       also drops. 760 = 930 - (100-15)*2 */
    max-width: 760px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
    padding-top: 30px;
    border-style: none;
  }
}

.large-font {
  font-size: 30px;
}


.handoutImg {
  max-width: min(500px, 100%);
}

// #slapplication-details {
//   padding: 20px;
// }

// #slapplication-applyButton {
//   margin-bottom: 20px;
// }

#applyButton:hover {
  background-color: rgb(181, 175, 175);
}

.referralContainer {
  display: flex;
  position: relative;
}
.refInput {
  flex-grow: 7;
}
.deleteButtonContainer {
  flex-grow: 1;
}
.deleteRef {
  border: none;
  background: none;
  color: lightgray;
  position: absolute;
  bottom: 40px;
  right: 10px;
}
.addButton {
  margin-top: 15px;
}
.formActionContainer {
  margin-top: 30px;
  display: flex;
}