.input-icon {
  position: absolute;
  left: 15px;
  top: calc(50% - 0.5em);
}

.input-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  right: 10px;
  top: calc(50% - 0.5em);
  border-radius: 50%;
  padding: 2px;
}

.close-button:hover {
  cursor: pointer;
  background-color: #888;
  color: #fff;
}

.searchIcon {
  width: 40px;
}

.forumSearchBox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 10px;
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.3);
  caret-color: #5a5e9a;
  color: #23263b;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  font: inherit;
  padding-left: 2.5rem;
  font-size: large;
  flex-grow: 1;
  height: 40px;
}

.hitsContainer {
  flex-grow: 1;
}

.paginationContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchResult {
  border: 1px solid lightgrey;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
}

.searchResultData {
  flex-grow: 1;
}

.searchResultStatus {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.searchResult:hover {
  cursor: pointer;
  background-color: rgb(239, 247, 250);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06), 0 2px 2px rgba(0, 0, 0, 0.06),
    0 4px 4px rgba(0, 0, 0, 0.06), 0 8px 8px rgba(0, 0, 0, 0.06),
    0 16px 16px rgba(0, 0, 0, 0.06);
}

.searchContainer {
  display: flex;
}
