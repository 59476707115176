.code-adventure .monaco-editor {
  .add-code-highlight {
    background-color: rgb(203, 195, 227);
    border: 2px dashed black;
    border-radius: 5px;
    padding: 2px;
  }

  $borderWidth: 2px;
  $padding: 2px;
  .remove-line-word-highlight {
    background-color: red;
    padding: $padding 0px;
    border-top: $borderWidth dashed black;
    border-bottom: $borderWidth dashed black;
    position: relative;
    left: -1 * ($borderWidth + $padding);

    // only first should have left border
    // do this by adding to all, and removing from subsequent
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: $borderWidth dashed black;
    padding-left: $padding;
    & ~ .remove-line-word-highlight {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: none;
      padding-left: 0px;
    }

    // only last should have right border
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-right: $borderWidth dashed black;
      padding-right: $padding;
    }
  }

  .cursors-layer .cursor {
    display: none !important;
  }
}
