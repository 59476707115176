.handout-tiptap {
  p {
    margin-bottom: 1em !important
  }

  h3 {
    margin-bottom: 0.5rem !important;
    margin-top: 10px !important;
  }

  width: 100%;
}

.ProseMirror {
  outline: none;

  >*+* {
    margin-top: 0em;
    margin-bottom: 0em;
  }

  h3 {
    margin-top: 0em;
    margin-bottom: 0em;
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    p {
      margin: 0;
    }

    li {
      display: flex;

      >label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      >div {
        flex: 1 1 auto;
      }
    }
  }

  iframe {
    border: 8px solid #000;
    border-radius: 4px;
    min-width: 200px;
    min-height: 200px;
    display: block;
    outline: 0px solid transparent;
  }

  div[data-youtube-video] {
    cursor: move;
    padding-right: 24px;
  }

  .ProseMirror-selectednode iframe {
    transition: outline 0.15s;
    outline: 6px solid #ece111;
  }

  >label {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    user-select: none;
  }


  blockquote {
    border-left: 3px solid rgba(#0d0d0d, 0.1);
    padding-left: 0.5rem;
    margin-left: 1rem;
  }

  pre {
    background: #f8f8f8;
    color: #383a42;
    font-family: "JetBrains Mono", monospace;
    padding: 0.75rem 1rem;
    overflow-x: scroll;
    white-space: pre !important;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }

    .hljs {
      color: #383a42;
      background-color: #f8f8f8;
    }

    .hljs-comment,
    .hljs-quote {
      color: #4e944d;
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-tag {
      color: #a626a4;
    }

    .hljs-attribute,
    .hljs-built_in,
    .hljs-literal,
    .hljs-template-tag,
    .hljs-template-variable {
      color: #e45649;
    }

    .hljs-meta {
      color: #383a42;
    }

    .hljs-number,
    .hljs-regexp {
      color: #986801;
    }

    .hljs-string,
    .hljs-section {
      color: #50a14f;
    }

    .hljs-symbol,
    .hljs-bullet {
      color: #4078f2;
    }

    .hljs-variable,
    .hljs-template-variable {
      color: #986801;
    }

    .hljs-type,
    .hljs-class {
      color: #c18401;
    }

    .hljs-title,
    .hljs-function {
      color: #4078f2;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: bold;
    }
  }

}



.ProseMirror-selectednode iframe {
  transition: outline 0.15s;
  outline: 6px solid #ece111;
}

.ProseMirror {


  padding: 0px !important;

  p {
    margin: 0px;
  }

  iframe {
    border: 1px solid #000;
    border-radius: 4px;
    min-width: 760px;
    min-height: 500px;
    display: block;
    outline: 0px solid transparent;
  }
  
  div[data-youtube-video] {
    cursor: move;
    padding-right: 24px;
  }

  

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
  
    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;
  
      >* {
        margin-bottom: 0;
      }
    }
  
    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }
  
    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }
  
    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }
  
    p {
      margin: 0;
    }
  }
  
}





.editor {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-radius: 0px 0px 5px;
  padding: 10px;
}

.ProseMirror {
  padding: 0px !important;

  p {
    margin: 0px;
  }

  img {
    max-width: 100%;
  }
}

.discussion-button-bar .dropdown-menu {
  // display: flex !important;
  // z-index: 9999999;
}

.discussion-button-bar .dropdown-item {
  display: table-cell;
  // padding: 10px !important;
  // padding-top:0px !important;
  // padding-bottom:0px !important;
}