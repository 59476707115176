.bottom-nav {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0px;
}

.stories-nav-style {
    width: 100%;
    margin-top:14px;
}

.storycard-img-top {
    width: 100%;
    height: 18rem;
    object-fit: cover;
}

.storycard {
    width: 100%;
    /* height: 36rem; */
    /* margin: 1rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.storycard-content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.story-edit-img {
    width: 100%;
    height: 18rem;
    object-fit: cover;
}

.edit-story-card {
    max-height: calc(100vh - 72px);
}

.edit-story-card-body {
    overflow: auto;
}

.edit-story-field {
    min-width: 500px;
    width: 100%;
    min-height: 18rem;
}

.edit-story-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.margin-top {
    margin-top: 10px;
}

.mb-0 {
    font-size: smaller;
}

.searchbox-btn {
    width: 2rem;
    height: 2rem;
}

.searchbox-input {
    width: calc(100% - 4rem);
}
/* 
.searchbox-root {
    position: absolute;
    top: 0;
} */

.stories-info {
    max-width: 100%;
}

.stories-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
}

.story-list {
    list-style: none;
    padding:0px;
}

.story-list-item {
    padding: 0px;
    box-shadow: none;
}

.story-list-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
}