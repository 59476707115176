.notificationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}


.notification {
  background-color: white;
}

.notification-new {
  background-color: rgb(255, 255, 237);
}

.notification:hover {
  background-color: lightgoldenrodyellow;
  cursor: pointer;
}

.notification-new:hover {
  background-color: lightgoldenrodyellow;
  cursor: pointer;
}

.unreadMarker {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  margin-right: 6px;
}

.notificationTitle {
  display: inline-flex;
  align-items: center;
}

.preposition {
  color: grey;
}

.notificationPostTitle {
  color: rgb(111, 180, 209);
}

.notificationHead {
  color: grey;

}

.notificationTime {
  color: lightgrey;
}

.emailPreferenceContainer {
  display: flex;
  justify-content: space-between;
}
