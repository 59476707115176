.referenceCel{
    margin-bottom:10px
}

.refCode {
    font-size:14px !important;
    line-height: 16px !important;
}

.refCode > * code {
    
    font-family:Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace !important;
    font-weight:600;
}

.refCode > code {
    
    font-family:Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace !important;
    font-weight:600;
}

.code-keyword {
    color: #0000f5 !important;
}

.code-comment {
    color:#5a876d;
    font-weight:200
}