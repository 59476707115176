.repliesContainer {
  display: flex;
  width: 100%;
}

.replies {
  width: 100%;
}

.replyContents {
  white-space: pre-line;
}

.replyBorder {
  border-left: 1px solid rgb(240, 240, 240);
  width: 5px;
  cursor: pointer;
  margin-right: 15px;
}

.replyBorder:hover {
  border-left: 2px solid rgb(200, 200, 200);
}

.showRepliesButton {
  color: grey;
  font-style: italic;
}
